export const smoothScroll = (elementId: string): void => {
  const element = document.querySelector(elementId);
  if (!element) return;

  const headerOffset = 80;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.scrollY - headerOffset;

  // Fallback for browsers that don't support smooth scrolling
  if (!('scrollBehavior' in document.documentElement.style)) {
    let start = window.scrollY;
    const target = offsetPosition;
    const duration = 1000;
    const startTime = performance.now();

    const animateScroll = (currentTime: number) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);

      // Easing function
      const easeInOutCubic = (t: number): number => {
        return t < 0.5
          ? 4 * t * t * t
          : 1 - Math.pow(-2 * t + 2, 3) / 2;
      };

      const easedProgress = easeInOutCubic(progress);
      const currentPosition = start + (target - start) * easedProgress;

      window.scrollTo(0, currentPosition);

      if (progress < 1) {
        requestAnimationFrame(animateScroll);
      }
    };

    requestAnimationFrame(animateScroll);
  } else {
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }
};

export const enableSmoothScroll = (): void => {
  if (typeof window !== 'undefined') {
    document.documentElement.style.scrollBehavior = 'smooth';
  }
};